<template>
  <div class='d-flex flex-column align-items-center' style="margin-left: 2rem; margin-right: 2rem;">
    <div v-if="loader" id="loader" class="loader">
      <div class="loader-text">
        Молимо сачекајте...
      </div>
      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="d-flex flex-column" :class="{'w-100': window_width < 780}">
      <b-card class="mb-4" :style="card_width" style="width:50rem;">
            <div class="d-flex flex-column">
              <h3 :style="title_style" style="background: #f8f8f8" class='text-center p-1 imei-search position-relative d-inline-block'>
                Претрага путем IMEI-a
              </h3>
              <div class="p-1 d-inline-block">
                <h5 :style="sub_title_style" style="font-weight: 400;" class="mb-1">Молимо унесите IMEI Вашег уређаја у поље испод.</h5>
                <div class="form">
                  <div class="input-btn-wrapper">
                    <b-input-group v-if="window_width > 533">
                      <b-form-input v-model="imei" id="imei-input"  placeholder="Пример: 863294029712934" />
                      <b-input-group-append>
                        <b-button @click="getQrCode()" variant="outline-primary">
                          <feather-icon
                          icon="SendIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Пошаљи</span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-input size="sm" v-if="window_width <= 533" v-model="imei" id="imei-input"  placeholder="Пример: 863294029712934" />
                    <b-button size="sm" v-if="window_width <= 533" style="margin: 0 auto; display:block; margin-top: 1.1rem;" @click="getQrCode()" variant="outline-primary">
                      <feather-icon
                          icon="SendIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Пошаљи</span>
                    </b-button>
                  </div>
                  <div :style="qr_code_style" class="qr-code-wrapper">
                    <img v-if="device.device === 'TB-8505X'" class="qr-img" src="../assets/images/qr_code/original_TB-8505X.png" alt="">
                    <img v-if="device.device === 'TB-X306X'" class="qr-img" src="../assets/images/qr_code/original_TB-X306X.png" alt="">
                    <img v-if="device.device === 'TB-X505L'" class="qr-img" src="../assets/images/qr_code/original_TB-X505L.png" alt="">
                    <img v-if="device.device === 'TB-X505X'" class="qr-img" src="../assets/images/qr_code/original_TB-X505X.png" alt="">
                    <img v-if="device.device === 'TB-X605LC'" class="qr-img" src="../assets/images/qr_code/original_TB-X605LC.png" alt="">                    
                    <img v-if="device.device === 'TB-X606X'" class="qr-img" src="../assets/images/qr_code/original_TB-X606X.png" alt="">                    
                  </div>
                  <div v-if="device.device === null" class="error-msg">
                    Молимо унесите исправан IMEI.
                  </div>
                </div>
                <div v-if="device != '' && device.device != null" style="text-align: center; margin-top: 10px; margin-bottom: 10px;">
                  Уколико сте ажурирали верзију софтвера на Вашем таблету на нову верзију Андроид оперативног система, кликните <span style="color: #305aef !important; text-decoration: underline;" v-b-modal.modal-newqr>ОВДЕ</span>
                </div>
                <a :style="sub_title_style" href="#imei-or-part">Како да пронађем IMEI мог уређаја?</a>
              </div>
            </div>
      </b-card>

      <b-card  class="mb-4 mt-3" :style="card_width" style="width:50rem;">
            <div class="d-flex flex-column">
              <h3 :style="title_style" style="background: #f8f8f8" class='text-center p-1 imei-search position-relative d-inline-block'>
                Претрага путем Part Number-a
              </h3>
              <div class="p-1 d-inline-block">
                <h5 :style="sub_title_style" style="font-weight: 400;" class="mb-1">Молимо унесите Part Number вашег уређаја у поље испод.</h5>
                <div class="form">
                  <div class="input-btn-wrapper">
                    <b-input-group v-if="window_width > 533">
                      <b-form-input v-model="partNumber" id="part-number-input"  placeholder="Пример: ZA5H0026SA" />
                      <b-input-group-append>
                        <b-button @click="getPartNumber()" variant="outline-primary">
                          <feather-icon icon="SendIcon" class="mr-50"/>
                          <span class="align-middle">Пошаљи</span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-input size="sm" v-if="window_width <= 533" v-model="partNumber" id="imei-input"  placeholder="Пример: ZA5H0026SA" />
                    <b-button size="sm" v-if="window_width <= 533" style="margin: 0 auto; display:block; margin-top: 1.1rem;" @click="getPartNumber()" variant="outline-primary">
                      <feather-icon
                          icon="SendIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Пошаљи</span>
                    </b-button>
                  </div>
                  <div :style="qr_code_style" class="qr-code-wrapper">
                    <img v-if="product === 'TB-8505X'" class="qr-img" src="../assets/images/qr_code/original_TB-8505X.png" alt="">
                    <img v-if="product === 'TB-X306X'" class="qr-img" src="../assets/images/qr_code/original_TB-X306X.png" alt="">
                    <img v-if="product === 'TB-X505L'" class="qr-img" src="../assets/images/qr_code/original_TB-X505L.png" alt="">
                    <img v-if="product === 'TB-X505X'" class="qr-img" src="../assets/images/qr_code/original_TB-X505X.png" alt="">
                    <img v-if="product === 'TB-X605LC'" class="qr-img" src="../assets/images/qr_code/original_TB-X605LC.png" alt="">
                    <img v-if="product === 'TB-X606X'" class="qr-img" src="../assets/images/qr_code/original_TB-X606X.png" alt="">
                  </div>
                  <div v-if="product === null" class="error-msg">
                    Молимо унесите исправан Part Number.
                  </div>
                </div>
                <div v-if="product != '' && product != null" style="text-align: center; margin-top: 10px; margin-bottom: 10px;">
                  Уколико сте ажурирали верзију софтвера на Вашем таблету на нову верзију Андроид оперативног система, кликните <span style="color: #305aef !important; text-decoration: underline;" v-b-modal.modal-newqr>ОВДЕ</span> 
                </div>
                <a :style="sub_title_style" href="#imei-or-part">Како да пронађем Part Number мог уређаја?</a>
              </div>
            </div>
            
      </b-card>
    </div>
    <div id="imei-or-part" class="mb-5"></div>
    <div :class="{'w-100': window_width < 780}">
      <b-card :style="card_width" style="width:50rem;">
        <h3 :style="title_style" style="background: #f8f8f8" class='text-center p-1 imei-search position-relative'>
          Како да пронађем Part Number и IMEI мог уређаја?
        </h3>
        <div>
          <h5 :style="sub_title_style" class='font-weight-lighter mt-2 mb-1' style="line-height: 20px; text-align: justify;">
            Part Number и IMEI Вашег уређаја можете пронаћи на позадини кутије таблета. Ова информација се налази у секцији означеној црвеном бојом, на слици 1. Такође, IMEI Вашег уређаја можете пронаћи и на реверсу који сте добили од школе.
          </h5>
          <img style="width:100%; height:100%;" src="../assets/images/step1.jpg" alt="">
          <div class='d-flex justify-content-center'>
            <h5 class="mt-1 d-inline-block" style="padding: .5rem; background: rgba(48,90,239, .9); border-radius: .2rem; color: #fff;">Слика 1</h5>
          </div>

          <h5 :style="sub_title_style" class='font-weight-lighter mt-3 mb-1' style="line-height: 20px; text-align: justify;">
            На слици 2 је обележена вредност која представља Part Number (сет бројева и слова, почиње са „ZA“)
            Испод ње је обележена вредност која представља IMEI уређаја.
          </h5>
          <img style="width:100%; height:100%;" src="../assets/images/step2.jpg" alt="">
          <div class='d-flex justify-content-center'>
            <h5 class="mt-1 d-inline-block" style="padding: .5rem; background: rgba(48,90,239, .9); border-radius: .2rem; color: #fff;">Слика 2</h5>
          </div>
        </div>
      </b-card>
    </div>
    
    <b-modal id="modal-newqr" ok-title="У реду" :hide-footer="true" centered size="s" title="QR код">
      <div>
        <img v-if="device.device === 'TB-8505X' || product === 'TB-8505X'" class="qr-img" src="../assets/images/qr_code/latest_TB-8505X.png" alt="">
        <img v-if="device.device === 'TB-X306X' || product === 'TB-X306X'" class="qr-img" src="../assets/images/qr_code/latest_TB-X306X.png" alt="">
        <img v-if="device.device === 'TB-X505L' || product === 'TB-X505L'" class="qr-img" src="../assets/images/qr_code/latest_TB-X505L.png" alt="">
        <img v-if="device.device === 'TB-X505X' || product === 'TB-X505X'" class="qr-img" src="../assets/images/qr_code/latest_TB-X505X.png" alt="">
        <img v-if="device.device === 'TB-X605LC' || product === 'TB-X605LC'" class="qr-img" src="../assets/images/qr_code/latest_TB-X605LC.png" alt="">
        <img v-if="device.device === 'TB-X606X' || product === 'TB-X606X'" class="qr-img" src="../assets/images/qr_code/latest_TB-X606X.png" alt="">
      </div>
    </b-modal>

  </div>
</template>

<script>
import {BCard, BTabs, BTab, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend, BModal, VBModal} from 'bootstrap-vue';
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal
  },
  created() {
    this.window_width = window.innerWidth
    window.addEventListener('resize', () => {
      this.window_width = window.innerWidth;
    })
  },
  data() {
    return {
      window_width: null,
      imei: '',
      device: '',
      loader: false,
      baseUrl: 'https://api.beotablet.rs/api/v1/',
      partNumber: '',
      product: '',
      // baseUrl: 'http://localhost:8000/api/v1/'
    }
  },
  computed: {
    card_width() {
      if(this.window_width < 780) {
        return {
          'width': 'auto'
        }
      }
    },
    title_style() {
      if(this.window_width > 320 && this.window_width < 576) {
        return {
          'font-size': '1.1rem'
        }
      }
    },
    sub_title_style() {
      if(this.window_width > 320 && this.window_width < 576) {
        return {
          'font-size': '.9rem'
        }
      }
    },
    qr_code_style() {
      if(this.window_width >= 355 && this.window_width < 600) {
        return {
          'max-width': '14rem'
        }
      } else if(this.window_width >= 600) {
        return {
          'max-width': '22rem'
        }
      }
    }
  },
  methods: {
    getQrCode() {
      document.documentElement.style.overflow = 'hidden';
      this.loader = true;
      fetch(this.baseUrl + 'product/getQrCode/' + this.imei, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then( response => {
        if (response.status != 200) {
          this.device = {device: null};
          this.loader = false;
          document.documentElement.style.overflow = 'auto';
        }
        return response.json();
      })
      .then( data => {
        this.device = data;
        this.loader = false;
        document.documentElement.style.overflow = 'auto';
      })
    },
    getPartNumber() {
      if (this.partNumber.toUpperCase() === 'ZA5H0026SA'.toUpperCase() || this.partNumber.toUpperCase() === 'ZA5H0016BG'.toUpperCase() || this.partNumber.toUpperCase() === 'ZA630026BG') {
        this.product = 'TB-8505X';
      } else if (this.partNumber.toUpperCase() === 'ZA4H0032BG'.toUpperCase() || this.partNumber.toUpperCase() === 'ZA4H0021SE'.toUpperCase() || this.partNumber.toUpperCase() === 'ZA4H0029BG'.toUpperCase()) {
        this.product = 'TB-X505L';
      } else if (this.partNumber.toUpperCase() === 'ZA4K0020SA'.toUpperCase()) {
        this.product = 'TB-X505X';
      } else if (this.partNumber.toUpperCase() === 'ZA6V0047BG'.toUpperCase() || this.partNumber.toUpperCase() === 'ZA6V0056SE'.toUpperCase()) {
        this.product = 'TB-X306X';
      } else if (this.partNumber.toUpperCase() === 'ZA500042BG'.toUpperCase() || this.partNumber.toUpperCase() === 'ZA500070BG'.toUpperCase()) {
        this.product = 'TB-X605LC';
      } else if (this.partNumber.toUpperCase() === 'ZA5V0074BG'.toUpperCase()) {
        this.product = 'TB-X606X';
      } else {
        this.product = null;
      }
    }
  }
}
</script>

<style lang="scss" scoped>


.error-msg {
  text-align:start;
  font-size: 1.07rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-left: .9rem;
  color: #ec1a49;
  position:relative;
}
.error-msg::before {
  content: '';
  position:absolute;
  left: -.6rem;
  top:50%;
  transform: translateY(-50%);
  height:6px;
  width:6px;
  border-radius: 50%;
  background:#ec1a49;

}
  .qr-code-wrapper {
    margin: 1.5rem auto 0;
    background:#f8f8f8;
  }
  .qr-code-wrapper-700 {
    max-width:12rem;
  }
  .qr-img {
    width: 100%;
    height: 100%;
  }
  .loader {
    position:fixed;
    inset: 0;
    background:rgba(255, 255, 255, 0.85);
    z-index: 999999;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
  }
  .loader-text {
    font-size: 1.6rem;
    margin-bottom: 1.3rem;
    animation: text-animation .8s ease-in-out infinite alternate;
  }
  @keyframes text-animation {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity:1;
    transform: scale(1.05)
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #2962ff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>